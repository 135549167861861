import $ from "jquery"

const changeGoogleStyles = () => {
  if (
    $(".goog-te-menu-frame").contents().find(".goog-te-menu2").length &&
    document.querySelector(".header").clientWidth < 1200
  ) {
    $(".goog-te-menu-frame")
      .contents()
      .find(".goog-te-menu2")
      .css({
        "max-width": `${document.querySelector(".header").clientWidth}px`,
        overflow: "scroll",
        "box-sizing": "border-box",
        height: "auto",
      })
  } else if (document.querySelector(".header").clientWidth < 1200) {
    setTimeout(changeGoogleStyles, 50)
  } else {
    return
  }
}

const addScript = () => {
  const script = document.createElement("script")
  script.src =
    "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  script.async = true
  document.body.appendChild(script)
}

const addScriptText = () => {
  const script = document.createElement("script")
  const text = document.createTextNode(`  
    var duplicate_google_translate_counter = 0;//this stops google adding button multiple times
    function googleTranslateElementInit() {
        if (duplicate_google_translate_counter == 0) {
            new google.translate.TranslateElement({pageLanguage: 'en', layout: google.translate.TranslateElement.InlineLayout.SIMPLE}, 'google_translate_element');
    
        }
        duplicate_google_translate_counter++;
        }
        `)
  script.appendChild(text)
  document.body.appendChild(script)
  if (
    $(".goog-te-menu-frame").contents().find(".goog-te-menu2").length &&
    document.querySelector("header")
  ) {
    $(".goog-te-menu-frame")
      .contents()
      .find(".goog-te-menu2")
      .css({
        "max-width": `${document.querySelector("header").clientWidth}px`,
        overflow: "scroll",
        "box-sizing": "border-box",
        height: "auto",
      })
  }
}

const getMenuHeirarchy = nodes => {
  const setHeirarchy = (
    data = [],
    { idKey = "key", parentKey = "parentId", childrenKey = "children" } = {}
  ) => {
    const menu = []
    data.forEach(item => {
      const heirarchy = item.menuItems.nodes
      const { name, id } = item
      const menuList = []
      const childrenOf = {}
      heirarchy.forEach(item => {
        const newItem = { ...item }
        const { [idKey]: id, [parentKey]: parentId = 0 } = newItem

        childrenOf[id] = childrenOf[id] || []
        newItem[childrenKey] = childrenOf[id]

        parentId
          ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
          : menuList.push(newItem)
      })
      menu.push({ name, id, menuItems: menuList })
    })
    return menu
  }

  const updated = setHeirarchy(nodes, {
    idKey: "id",
    parentKey: "parentId",
    childrenKey: "children",
  })
  return updated
}

const removeWPLink = url => {
  if (url && typeof url === 'string') {
    return url?.replace(
      process.env.WPGRAPHQL_URL || `https://wordpress.boht-test2.dev.identos.ca`,
      ""
    )
  };
  return url;
}

/**
 * truncates word with a Max character limit set in the fn body.
 * @param {*} word is string
 * @returns if word is greater than max character limit then string is going be substringed and added .. to it, otherwise return same word
 */
const truncateWord = word => {
  const MAX_CHAR_LIMIT = 38

  if (word.length <= MAX_CHAR_LIMIT) {
    return word
  }

  let newWord = word.slice(0, MAX_CHAR_LIMIT)
  newWord = newWord.trim()

  return `${newWord}..`
}

/**
 * check to see if the linkType is external and conditionally adds a new blank target
 * @param {*} isExternal
 * @returns Anchor Link attributes target & rel | void
 */
 const openExternalLink = isExternal => {
  if (isExternal === "External Link") {
    return externalLink
  }
}

const externalLink = {
  target: "_blank",
  rel: "noopener noreferrer",
}

export {
  changeGoogleStyles,
  addScript,
  addScriptText,
  getMenuHeirarchy,
  removeWPLink,
  truncateWord,
  openExternalLink,
  externalLink
}
